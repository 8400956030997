import React from "react"
import { useIntl } from "react-intl"
import { switchLocale } from "../../lib/helpers"
import { Button, ButtonProps } from "reactstrap"
import { Locales } from "../../lib/types/shared"

export const LanguageSwitch: React.FC<ButtonProps> = ({ ...buttonProps }) => {
  const intl = useIntl()
  return (
    <Button
      {...buttonProps}
      onClick={() => switchLocale(intl.locale as Locales)}
    >
      {intl.locale.toUpperCase()}
    </Button>
  )
}
