import React from "react"
import Link from "next/link"
import { FormattedMessage } from "react-intl"
import { LanguageSwitch } from "../shared/language-swtich"
import { config } from "../../lib/config"
import { Image } from "../shared/image"

const BestCareerHeader: React.FC = () => {
  return (
    <div className="header bg-dark">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Link href="/jobs">
              <a className="d-block py-3">
                <Image
                  width={180}
                  height={28}
                  alt="BestCareer"
                  src="/web-catalog-app/assets/bestcareer/best_career_solo-white.png"
                />
              </a>
            </Link>
          </div>
          <div>
            <a className="btn btn-primary btn-sm" href={config.urls.companyApp}>
              <span className="text-uppercase text-nowrap">
                <FormattedMessage
                  id="bestcareer.header.partnerLogin"
                  defaultMessage="Partner login"
                />
              </span>
            </a>
            <LanguageSwitch
              size="sm"
              color="link"
              className="text-white text-decoration-none ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestCareerHeader
